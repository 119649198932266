import EventBus from '@/util/EventBus.js'
import PaymentService from '@/services/payment.service.js'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import PlanPrice from './Price/PlanPrice.vue'
import SmsPriceDatatable from './SmsPriceDatatable/SmsPriceDatatable.vue'
import PlanSelected from './PlanSelected/PlanSelected.vue'
import AddBalance from './AddBalance/AddBalance.vue'

export default {
  name: 'PlanAndPrice',
  components: {
    HeaderTopDashboard,
    PlanPrice,
    SmsPriceDatatable,
    PlanSelected,
    AddBalance,
  },
  data () {
    return {
      plans: null,
      dialog: false,
      smsPriceDataTable: [],
      planSelected: null,
      mainSmsPrice: null,
      mainCertificateSmsPrice: null,
      mainMailPrice: null,
      mainCountryName: null,
      mainCountryIso: null,
      currencySymbol: null,
      currencyRate: null,
      mainMailValidationPrice: null,
    }
  },
  computed: {
    displayUserCountryInfo: function () {
      return this.mainCountryIso !== null
    },
  },
  methods: {
    getPlanPrices () {
      PaymentService.getPlanPrices().then(
        (response) => {
          const currency = response.currency
          this.currencySymbol = currency.symbol
          this.plans = response.plans
          const country = response.userCountry
          this.mainCountryName = country.name
          this.mainCountryIso = country.iso_code_2
          this.smsPriceDataTable = Object.values(response.countriesMaxPricesInfo[0])
          this.planSelected = response.userPlan
          this.mainSmsPrice = this.formatAmount(response.userCountrySmsPrice, this.mainCountryIso)
          this.mainCertificateSmsPrice = this.formatAmount(response.userCertifiedSmsCost, this.mainCountryIso)
          this.mainMailPrice = this.formatAmount(response.defaultPlanUser, this.mainCountryIso)
          this.currencyRate = response.currencyRate
          this.mainMailValidationPrice = this.formatAmount(0.30 * this.currencyRate, this.mainCountryIso, 2, 2)
        },
        () => {},
      )
    },
    formatAmount (amount, country, minimumFractionDigits = 2, maximumFractionDigits = 6) {
      return new Intl.NumberFormat(country.toLowerCase(), {
        minimumFractionDigits,
        maximumFractionDigits,
      }).format(amount)
    },
    setIsoFlag (isoCode) {
      const isoToLower = isoCode.toLowerCase()
      const lang = isoToLower === 'en' ? 'gb' : isoToLower
      return require('@/assets/img/flags/' + lang + '.svg')
    },
    showPlansSmsPriceByCountry () {
      EventBus.$emit('showSmsPriceDataTablePlans')
    },
    showPlanSelected () {
      EventBus.$emit('showPlanAndPricePlanSelected')
    },
    addBalance () {
      EventBus.$emit('showPlanAndPriceAddBalance')
    },
  },
  created () {
    this.getPlanPrices()
  },
}
