import EventBus from '@/util/EventBus.js'
import { Country } from '@/util/Country.js'

export default {
  name: 'SmsPriceDatatable',
  props: {
    smsPriceDataTable: { type: Array, require: true },
    userCountry: { type: String, require: true },
    userCurrency: { type: String, require: true },
  },
  data () {
    return {
      show: false,
      loading: true,
      options: {},
      content: [],
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      total: 0,
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'iso2',
        },
        { text: this.$t('País'), value: 'countryName' },
        { text: this.$t('Precio desde'), value: 'cost' },
      ],
      Country: Country,
    }
  },
  watch: {
    smsPriceDataTable () {
      this.content = JSON.parse(JSON.stringify(this.smsPriceDataTable))
    },
  },
  methods: {
    formatAmount (amount, country) {
      return new Intl.NumberFormat(country, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 6,
      }).format(amount)
    },
    toogle () {
      this.show = !this.show
    },
    getDataFromApi () {
      this.loading = false
      this.content = JSON.parse(JSON.stringify(this.smsPriceDataTable))
    },
  },
  created () {
    EventBus.$on('showSmsPriceDataTablePlans', () => {
      this.toogle()
      this.getDataFromApi()
    })
  },
}
