import EventBus from '@/util/EventBus.js'

export default {
  name: 'PlanSelected',
  data () {
    return {
      show: false,
    }
  },
  methods: {
    toogle () {
      this.show = !this.show
    },
  },
  created () {
    EventBus.$on('showPlanAndPricePlanSelected', () => {
      this.toogle()
    })
  },
}
